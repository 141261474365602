const config = require('./' + process.env.NODE_ENV);
const sentry_dsn = process.env.SENTRY_DSN;
const dd_token = process.env.DD_TOKEN;

export default class Environment {
    static get SENTRY_DSN() {
        return sentry_dsn;
    }
    static get DATADOG_TOKEN() {
        return dd_token;
    }
    static get ENVIRONMENT() {
        return config.environment;
    }
    static get PROTOCOL() {
        return config.protocol;
    }
    static get ADREQ_DOMAIN() {
        return config.adReq.domain;
    }
    static get ADREQ_PATH() {
        return config.adReq.path;
    }
    static get SAFEFRAME_DOMAIN() {
        return config.safeFrame.domain;
    }
    static get SAFEFRAME_PATH() {
        return config.safeFrame.path;
    }
    static get TRACKER_DOMAIN() {
        return config.tracker.domain;
    }
    static get TRACKER_EVENT_PATH() {
        return config.tracker.eventPath;
    }
    static get OPTOUT_DOMAIN() {
        return config.optout.domain;
    }
    static get OPTOUT_PATH() {
        return config.optout.path;
    }
}
