const config = {
    environment: "development",
    protocol: "https",
    adReq: {
        domain: "adreq-eagle-insight.dev5s.com",
        path: "/search",
    },
    safeFrame: {
        domain: "safeframe.dev.eagle-insight.com",
        path: "/safeframe/html/container.html",
    },
    tracker: {
        domain: "e.dev.eagle-insight.com",
        eventPath: "/event",
    },
    optout: {
        domain: "adreq-eagle-insight.dev5s.com",
        path: "/optout",
    },
};

module.exports = config;
