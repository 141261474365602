import env from '../../../environment/settings';
const settings = {
    protocol: env.PROTOCOL,
    safeFrameDomain: env.SAFEFRAME_DOMAIN,
    safeFramePath: env.SAFEFRAME_PATH
};

const url = settings.protocol + '://' + settings.safeFrameDomain + '/eisatag/js/main.js';
const script = document.createElement('script');
script.src = url;
document.body.appendChild(script);
